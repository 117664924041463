import React, { createContext, useState } from 'react';
const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const [cartCount, setCartCount] = useState(0);
  const [userToken, setUserToken] = useState('');
  
  const [settingData, setSettingData] = useState({});
  const [settingImageBaseUrl, setSettingImageBaseUrl] = useState('');
  const [defaultAddressStatus, setDefaultAddressStatus] = useState(false);
  /* Modals  */
  const [toggleLoginModal, setToggleLoginModal] = useState(false);
  const [toggleCheckoutModal, setToggleCheckoutModal] = useState(false);
  const [toggleQuickViewModal, setToggleQuickViewModal] = useState(false);
  const [toggleVariationModal, setToggleVariationModal] = useState(false);
  const [toggleCouponModal, setToggleCouponModal] = useState(false);
  const [toggleCartModal, setToggleCartModal] = useState(false);
  const [toggleMenuModal, setToggleMenuModal] = useState(false);
  const [toggleCheckoutAddressModal, setToggleCheckoutAddressModal] = useState(false);
  const [togglePaymentModal, setTogglePaymentModal] = useState(false);
  /* END */
  const [productData, setProductData] = useState({});
  const [spinnerCubLoader, setSpinnerCubLoader] = useState(0);
  const [cartSummary, setCartSummary] = useState({});
  const [couponSession, setCouponSession] = useState({});
  const [addressSession, setAddressSession] = useState({});
  const [cartSessionData, setCartSessionData] = useState([]);
  const [userAddressList, setUserAddressList] = useState([]);
  const [userSelectedAddress, setUserSelectedAddress] = useState({});
  return (
    <DataContext.Provider value={
      {
        userToken, setUserToken,
        defaultAddressStatus, setDefaultAddressStatus,
        settingData, setSettingData,
        settingImageBaseUrl, setSettingImageBaseUrl,
        
        productData, setProductData,
        spinnerCubLoader, setSpinnerCubLoader,
        cartCount, setCartCount,
        cartSummary, setCartSummary,
        couponSession, setCouponSession,
        addressSession, setAddressSession,
        cartSessionData, setCartSessionData,
        userAddressList, setUserAddressList,
        userSelectedAddress, setUserSelectedAddress,

        toggleLoginModal, setToggleLoginModal,
        toggleCheckoutModal, setToggleCheckoutModal,
        toggleQuickViewModal, setToggleQuickViewModal,
        toggleVariationModal, setToggleVariationModal,
        toggleCouponModal, setToggleCouponModal,
        toggleCartModal, setToggleCartModal,
        toggleMenuModal, setToggleMenuModal,
        toggleCheckoutAddressModal, setToggleCheckoutAddressModal,
        togglePaymentModal, setTogglePaymentModal,
      }
    }>
      {children}
    </DataContext.Provider>
  );
};

export default DataContext;