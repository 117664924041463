import React, { useEffect, useState, useRef } from "react";
import { ApiService } from "../../Components/Services/apiservices";
import { useNavigate, useParams } from "react-router-dom";
import { BrowserView, MobileView } from "react-device-detect";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import MobileHeader from "../../Components/Elements/mobile_header";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import ProductBox from "../../Components/Elements/product_box";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "react-js-loader";
function SearchResult() {
    const navigate = useNavigate();
    const { slug } = useParams();
    const [resProductsData, setResProductsData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(1);
    useEffect(() => {
        fetchSearchResults()
    }, []);

    const fetchSearchResults = () => {
        const dataString = {
            search_filter: slug,
        };

        ApiService.postData(`getsearchresultdata?page=${page}`, dataString).then((res) => {
            if (res.status === "success") {
                setResProductsData((prevData) => [...prevData, ...res.data.data]);
                setLoading(false);
                if (res.data.data.length === 0) {
                    setHasMore(false);
                }
                setPage((prevPage) => prevPage + 1);
            } else {
                setLoading(false);
                setHasMore(false);
            }
        });
    };
    return (
        <>
            <BrowserView>
                <Header state="inner-header" innerHeader="innerHeader" />
                <main>
                    <div className="page-content mt-5 mb-5">
                        <Container>
                            <Row>
                                <Col lg={12}>
                                    <InfiniteScroll
                                        dataLength={resProductsData.length}
                                        next={fetchSearchResults}
                                        hasMore={hasMore}
                                        loader={<Loader type="spinner-default" bgColor={'#674387'} color={'#674387'} size={50} />}
                                        style={{ overflow: 'hidden !important' }}
                                    >
                                        {loading === true ? (
                                            <Row >
                                                {[...Array(9)].map((_, index) => (
                                                    <Col lg={3}>
                                                        <div className="product" key={index}>
                                                            <figure className="product-media">
                                                                <Skeleton
                                                                    variant="text"
                                                                    width={280}
                                                                    height={315}
                                                                />
                                                            </figure>
                                                            <div className="product-details">
                                                                <h3 className="product-name">
                                                                    <Skeleton variant="text" width={150} />
                                                                </h3>
                                                                <div className="product-price">
                                                                    <Skeleton variant="text" width={150} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                ))}
                                            </Row>
                                        ) : (
                                            <>
                                                {resProductsData.length > 0 ? (
                                                    <Row>
                                                        {resProductsData.map((value, indexProduct) => { 
                                                            return (
                                                                <Col lg={3} key={indexProduct}>
                                                                    <ProductBox productValue={value} indexProduct={indexProduct} classType="cproduct" key={indexProduct} />
                                                                </Col>
                                                            );
                                                        })}
                                                    </Row>
                                                ) : (

                                                    <h2>No results could be found for "{slug}"</h2>
                                                )}
                                            </>
                                        )}
                                    </InfiniteScroll>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </main>
                <Footer />
            </BrowserView>
            <MobileView>
                <MobileHeader PageName={'Search'} />
                <main className="main">
                    <div className="page-content mt-3 mb-5">
                        <Container>
                            <InfiniteScroll
                                dataLength={resProductsData.length}
                                next={fetchSearchResults}
                                hasMore={hasMore}
                                loader={<Loader type="spinner-default" bgColor={'#674387'} color={'#674387'} size={50} />}
                                style={{ overflow: 'hidden !important' }}
                            >
                                {loading === true ? (
                                    <Row >
                                        {[...Array(9)].map((_, index) => (
                                            <Col lg={3}>
                                                <div className="product" key={index}>
                                                    <figure className="product-media">
                                                        <Skeleton
                                                            variant="text"
                                                            width={280}
                                                            height={315}
                                                        />
                                                    </figure>
                                                    <div className="product-details">
                                                        <h3 className="product-name">
                                                            <Skeleton variant="text" width={150} />
                                                        </h3>
                                                        <div className="product-price">
                                                            <Skeleton variant="text" width={150} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        ))}
                                    </Row>
                                ) : (
                                    <>
                                        {resProductsData.length > 0 ? (
                                            <div className="row g-2">
                                                {resProductsData.map((value, indexProduct) => { 
                                                    return (
                                                        <div className="col-6" key={indexProduct}>
                                                            <ProductBox productValue={value} indexProduct={indexProduct} classType="cproduct" key={indexProduct} />
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        ) : (

                                            <h2>No results could be found for {slug}</h2>
                                        )}
                                    </>
                                )}
                            </InfiniteScroll>
                        </Container>
                    </div>
                </main>
            </MobileView>
        </>
    )
}
export default SearchResult
