import React, { useEffect, useRef, useState,useContext } from "react";
import { ApiService } from "../../Components/Services/apiservices";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import { Col, FormLabel, Row } from "react-bootstrap";
import { validEmail, validNumber } from "../../Components/Elements/Regex";
import Alert from "react-bootstrap/Alert";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { useParams } from "react-router-dom";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import constant from "../../Components/Services/constant";
import { Helmet } from "react-helmet";
import DataContext from "../../Components/Elements/eventContext";

function Contact() {
  const { slug } = useParams();
  const didMountRef = useRef(true);
  const [pageData, setPageData] = useState({}); 
  const [pageContent, setPageContent] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [whatsappUrl , setWhatsappUrl] = useState('')
  const contextValues = useContext(DataContext) 
  const [contactDetails, setContactDetails] = useState({
    contact_name: "",
    contact_email: "",
    contact_mobile: "",
    contact_message: "",
  });
  useEffect(() => {
    if (didMountRef.current) {
      // getSettingsData();
      const getPageData = {
        slug: "contact-us",
      };
      ApiService.postData("page-content", getPageData).then((res) => {
        if (res.status == "success") {
          setPageData(res.data);
          setPageContent(res.data.page_content);
        }
      });
    }
    didMountRef.current = false;
  });

  const onTodoChange = (e) => {
    const { name, value } = e.target;
    setContactDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const resetContactForm = () => {
    setContactDetails({
      contact_name: "",
      contact_email: "",
      contact_mobile: "",
      contact_message: "",
    });
  };
  const contactusProcess = () => {
    let counter = 0;
    const myElements = document.getElementsByClassName("required");
    for (let i = 0; i < myElements.length; i++) {
      if (myElements[i].value === "") {
        myElements[i].style.border = "1px solid red";
        counter++;
      } else {
        myElements[i].style.border = "";
      }
    }
    if (counter === 0) {
      setErrorMessage("");
      if (!validEmail.test(contactDetails.contact_email)) {
        setErrorMessage("Please enter valid Email Id");
      } else if (!validNumber.test(contactDetails.contact_mobile)) {
        setErrorMessage("Please enter valid Mobile Number");
        return false;
      }
      ApiService.postData("contact-us-process", contactDetails).then((res) => {
        if (res.status === "success") {
          setSuccessMessage(res.message);
          resetContactForm();
          setTimeout(() => {
            setSuccessMessage("");
          }, 2000);
        } else {
          setErrorMessage(res.message);
          setTimeout(() => {
            setErrorMessage("");
          }, 2000);
        }
      });
    }
  };

  return (
    <>
       <Helmet>
        <title>{pageData.page_meta_title}</title>
        <meta name="description" itemprop="description" content={pageData.page_meta_desc != null ? pageData.page_meta_desc :""} />
        {pageData.page_meta_keyword != null ?<meta name="keywords" content={pageData.page_meta_keyword} />:""}
        <link rel="canonical" href={window.location.href} />
        <meta property="og:title" content={pageData.page_meta_title} />
        <meta name="twitter:url" content={window.location.href} />
             <meta property="og:image" content= {constant.FRONT_URL+'img/logo.png'}/>
        <meta property="og:url" content={window.location.href} />
        {pageData.page_meta_desc != null ?<meta property="og:description" content={pageData.page_meta_desc} />:""}
        <meta name="twitter:title" content={pageData.page_meta_title} />
        {pageData.page_meta_desc != null ?<meta name="twitter:description" content={pageData.page_meta_desc} />:""}
            <meta property="twitter:image" content= {constant.FRONT_URL+'img/logo.png'}/>
      </Helmet>
     <Header state="inner-header" innerHeader="innerHeader"/>
      
          <div className="subheader">
            <Container>
              <Row>
                <Col lg={12}>
                  <h1>{pageData.page_name}</h1>
                  <Breadcrumb>
                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>

                    <Breadcrumb.Item active>
                      {pageData.page_name}
                    </Breadcrumb.Item>
                  </Breadcrumb>
                </Col>
              </Row>
            </Container>
          </div>

          <section className="sec-gap-medium">
            <Container>
              <Row>
                <Col lg={7}>
                  <div className="section-title">
                    <h4>Get In Touch With Us</h4>
                    <p>
                      For any feedback, queries or to get help with sizing,
                      please call or Whatsapp us at{" "}
                      <a href={"https://wa.me/" + contextValues.settingData.admin_whatsapp_no} target="new">
                        {contextValues.settingData.admin_mobile}
                      </a>
                    </p>
                    <p>
                      <strong>
                        Timings 10:00 AM - 7:00 PM IST (Monday - Saturday)
                      </strong>
                    </p>
                  </div>
                  {errorMessage && (
                    <Alert variant="danger">{errorMessage}</Alert>
                  )}
                  {successMessage && (
                    <Alert variant="success">{successMessage}</Alert>
                  )}
                  <div className="row g-3">
                    <div className="col-lg-6">
                      <div className="form-group-dark">
                        <label>Your Name</label>
                        <input
                          type="text"
                          name="contact_name"
                          className="form-control required"
                          value={contactDetails.contact_name}
                          onChange={(e) => onTodoChange(e)}
                          placeholder="Name"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group-dark">
                        <label>Email Address</label>
                        <input
                          type="text"
                          name="contact_email"
                          className="form-control required"
                          value={contactDetails.contact_email}
                          onChange={(e) => onTodoChange(e)}
                          placeholder="Email"
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group-dark">
                        <label>Phone</label>
                        <input
                          type="number"
                          name="contact_mobile"
                          className="form-control required"
                          value={contactDetails.contact_mobile}
                          onChange={(e) => onTodoChange(e)}
                          placeholder="Phone"
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group-dark">
                        <label>Message</label>
                        <textarea
                          name="contact_message"
                          className="form-control required"
                          value={contactDetails.contact_message}
                          onChange={(e) => onTodoChange(e)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <Button
                        className="btn btn-primary btn-medium"
                        onClick={contactusProcess}
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </Col>
                <Col lg={5}>
                  <div className="contact-address-section mb-3">
                    <div className="section-title">
                      <h4>Contact Info</h4>
                    </div>
                    <div className="address-contact">
                      <ul>
                        <li>
                          <a
                            href="https://maps.app.goo.gl/6CMhx6NWDweHnivCA"
                            target="new"
                          >
                              <i className="d-icon-map mr-5"></i>
                            <span> {contextValues.settingData.address}</span>
                          </a>
                        </li>
                        <li>
                          <a href={"mailto:" + contextValues.settingData.admin_email}>
                          <i className="fa fa-envelope  mr-5"></i>
                            <span> {contextValues.settingData.admin_email}</span>
                          </a>
                        </li>
                        <li>
                          <a href={"tel:" + contextValues.settingData.admin_mobile}>
                          <i className="d-icon-phone mr-5"></i>
                            <span> {contextValues.settingData.admin_mobile}</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div> 

                  
                </Col>
              </Row>
            </Container>
          </section>
    

      <Footer />
    </>
  );
}
export default Contact;
