// const API_URL =   "https://www.tamolo.in/projects/kalagram/csadmin/api/"
const API_URL =   "https://chokhidhanikalagram.com/csadmin/api/"
const BASE_URL =   "https://chokhidhanikalagram.com/"  
const FRONT_URL =   "https://chokhidhanikalagram.com/" 
const LOGO_IMAGE =   "/img/logo.png" 

const DEFAULT_IMAGE = "/img/defaultimage.jpg";
const CATEGORIES = [];
const themesetting = {}; 
export default { API_URL, CATEGORIES, themesetting, BASE_URL,FRONT_URL,DEFAULT_IMAGE,LOGO_IMAGE };
