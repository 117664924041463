import React, { useEffect, useState, useRef } from 'react'
import constant from "../../../Components/Services/constant";
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css';
import 'swiper/css/navigation';
import { Autoplay } from 'swiper/modules'
import { ApiService } from '../../../Components/Services/apiservices';
function CelebritiesAv({ dataObj }) {
    const didMountRef = useRef(true);
    const [celebrity, setCelebrity] = useState([]);
    const [celebrityImagePath, setCelebrityImagePath] = useState("");
    useEffect(() => {
        if (didMountRef.current) {
            getCelebritiesData()
        }
        didMountRef.current = false;
    }, [])

    const getCelebritiesData = () => {
        ApiService.fetchData("celebrityImages").then((res) => {
            if (res.status == "success") {
                setCelebrity(res.ImagesData);
                setCelebrityImagePath(res.ImagesPath);
            }
        })
    }
    const productCarouselOptions = {
        slidesPerView: dataObj.home_column,
        spaceBetween: 10,
        loop: true,
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        autoplay: {
            delay: 2000,
            disableOnInteraction: false,
        },
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
        breakpoints: {
            0: {
                slidesPerView: 2.2,
            },
            600: {
                slidesPerView: 2,
            },
            1000: {
                slidesPerView: 4.4,
            },
        },
    };
    return (
        <>
            {celebrity.length > 0 && (
                <>
                    {dataObj.home_display_type === 2 ? (
                        <div>
                            <div className={"row g-3 " + dataObj.home_col_grid + dataObj.home_column}>
                                {celebrity.map((celeb, index) => (
                                    <div className='celebratebox' key={index}>
                                        <img
                                            src={celeb.img_image != null ? celebrityImagePath + celeb.img_image : constant.DEFAULT_IMAGE}
                                            alt={celeb.img_name}
                                        />
                                        <div className='celebrateboxText'>{celeb.img_name}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ) : (
                        <div>
                            <Swiper {...productCarouselOptions} modules={[Autoplay]}>
                                {celebrity.map((celeb, index) => (
                                    <SwiperSlide key={index}>
                                        <div className='celebratebox'>
                                            <img
                                                src={celeb.img_image != null ? celebrityImagePath + celeb.img_image : constant.DEFAULT_IMAGE}
                                                alt={celeb.img_name}
                                            />
                                            <div className='celebrateboxText'>{celeb.img_name}</div>
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    )}
                </>
            )}

        </>
    )
}

export default CelebritiesAv
