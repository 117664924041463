import axios from 'axios';
import constant from './constant';

const client = axios.create({
  baseURL: constant.API_URL,
});

// Request interceptor
client.interceptors.request.use(
  (config) => {
    // Modify the config object before sending the request
    //console.log('Request interceptor');
    //console.log('Request config:', config);
    return config;
  },
  (error) => {
    // Handle request error
    //console.error('Request interceptor error:', error);
    return Promise.reject(error);
  }
);

// Response interceptor
client.interceptors.response.use(
  (response) => {
    // Handle the response data
    //console.log('Response interceptor');
    // const currentUrl = window.location.href;
    // const containsQuestionMark = currentUrl.includes('?');
    // if(containsQuestionMark){
    //   window.location.href = '/error_404';
    // }
   // console.log('Response data:', response.data);
    return response;
  },
  (error) => {
    // Handle response error
    //console.error('Response interceptor error:', error);
    return Promise.reject(error);
  }
);
function getauthtoken(){
  let token = localStorage.getItem('USER_TOKEN');
  let Authtoken = '';
  if(token !=null && token !='' && token !=undefined){
      Authtoken = token;
  }
  const config = {
    headers: { 'X-Authorization': `Bearer ${Authtoken}` }
  };
  return config;
}
export class ApiService {
  static async fetchData(url) {
    try {
      const response = await client.get(url, getauthtoken());
      return response.data;
    } catch (error) {
      //console.error('Error fetching data:', error);
      throw error;
    }
  }

  static async postData(url,data) {
    try {
      const response = await client.post(url, data, getauthtoken());
      return response.data;
    } catch (error) {
      //console.error('Error posting data:', error);
      throw error;
    }
  }
  static async filterPostData(url,pageNumber,data) {
    try {
      const response = await client.post(url,pageNumber, data, getauthtoken());
      return response.data;
    } catch (error) {
      //console.error('Error posting data:', error);
      throw error;
    }
  }

  static async numberFormat(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
}
