import React, { useEffect, useRef, useState } from "react";
import { ApiService } from "../../../Components/Services/apiservices";
import Skeleton from "react-loading-skeleton";
import { Swiper, SwiperSlide } from "swiper/react";

function CategoriesList({ dataObj }) {
    const [spinnerLoading, setSpinnerLoading] = useState(true);
    const didMountRef = useRef(true);
    const [categoryData, setCategoryData] = useState([]);
    const [defaultImage, setDefaultImage] = useState('');
    const [categoryImagePath, setCategoryImagePath] = useState('');

    useEffect(() => {
        if (didMountRef.current) {
            const dataString = {
                data: dataObj,
            };
            ApiService.postData("categoryByIds", dataString).then((res) => {
                if (res.status == "success") {
                    setDefaultImage(res.default_image_path)
                    setCategoryImagePath(res.categoryImageUrl)
                    setCategoryData(res.resCategoryData);
                    setSpinnerLoading(false);
                } else {
                    setSpinnerLoading(false);
                }
            });
        }
        didMountRef.current = false;
    }, []);
    return (
        <>
            {spinnerLoading ? (
                <div className="row g-2 cols-8">
                    {[...Array(8)].map((_, index) => (
                        <div className="category overlay-zoom" key={index}>
                            <Skeleton variant="text" width={'100%'} height={250} />
                        </div>

                    ))}
                </div>
            ) : (
                categoryData && categoryData.length > 0 ? (
                    Number(dataObj.home_display_type) === 2 ? (
                        <div className={`row g-3 cols-${dataObj.home_column}`}>
                            {categoryData.map((value, index) => (

                                <div class="category overlay-zoom" key={index}>
                                    <a href={"/collection/category/" + value.cat_slug}>
                                        <figure className="category-media">
                                            {value.cat_video ?
                                                <video src={`${categoryImagePath}${value.cat_video}`} muted autoPlay loop /> :
                                                <img src={value.cat_image !== null && value.cat_image !== "" ? categoryImagePath + value.cat_image : defaultImage} alt={value.cat_name} />}
                                        </figure>
                                        {dataObj.home_cat_display_title == 1 && (<div className="category-name">{value.cat_name}</div>)}
                                     
                                    </a>
                                    
                                </div>

                            ))}
                        </div>
                    ) : (

                        <Swiper
                            loop={true}
                            spaceBetween={10}
                            className="featuredcatSlider"
                            navigation={{
                                nextEl: ".swiper-button-next",
                                prevEl: ".swiper-button-prev",
                            }}
                            slidesPerView={dataObj.home_column}
                        >
                            {categoryData.map((value, index) => (
                                <SwiperSlide key={index}>
                                   <div class="category overlay-zoom" key={index}>
                                    <a href={"/collection/category/" + value.cat_slug}>
                                        <figure className="category-media">
                                            {value.cat_video ?
                                                <video src={`${categoryImagePath}${value.cat_video}`} muted autoPlay loop /> :
                                                <img src={value.cat_image !== null && value.cat_image !== "" ? categoryImagePath + value.cat_image : defaultImage} alt={value.cat_name} />}
                                        </figure>
                                        {dataObj.home_cat_display_title == 1 && (<div className="category-name">{value.cat_name}</div>)}
                                     
                                    </a>
                                    
                                </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>

                    )
                ) : null
            )}
        </>
    );
}

export default CategoriesList;
