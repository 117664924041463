import React, { useEffect, useState, useRef } from "react";
import { ApiService } from "../../Components/Services/apiservices";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { BrowserView, MobileView } from "react-device-detect";
import Modal from "react-bootstrap/Modal";
import { useParams } from "react-router-dom";
function Productlistsidebar({
  sorting = null,
  slug,
  showmodal,
  }) {
  const didMountRef = useRef(true);
  const [resCollectionList , setResCollectionList] = useState([])

  useEffect(() => {
    if (didMountRef.current) {   
      getProductListSidebar();
     }
    didMountRef.current = false;
  });

  const getProductListSidebar = () => {
    ApiService.fetchData("get-product-list-sidebar").then((res) => {
      if (res.status === "success") {
        // setAttributesList(res.resAttributesList);
        // setCategoryList(res.aryCategoryList);
        setResCollectionList(res.resCollectionList)
        // setResProductTypeList(res.resProductTypeList)
      } else {
      }
    });
  };

  const handleFilterCollection = (value) => {
    console.log('asassluggg',slug);
    window.location.href = '/collection/'+value.cat_slug;
  };

  return (
    <>
    <BrowserView className="col-lg-3 siderbar-filter">
    <aside>
    <div className="filterbox mb-3">
            <div className="filterbox-title">Shop by Collection</div>
            <div className="filterbox-body filter-common filter-height">
              <div className="filter-list">
                <ul>
                {resCollectionList.length > 0 &&
                        resCollectionList.map((value) => (                           
                              <li key={value.cat_id} >
                                <input
                                  type="checkbox" 
                                  onClick={(e) =>
                                    handleFilterCollection(value)
                                  }
                                  // checked= {JSON.parse(localStorage.getItem('FILTER_SESSION')) && JSON.parse(localStorage.getItem('FILTER_SESSION'))['collection'] && JSON.parse(localStorage.getItem('FILTER_SESSION'))['collection'].includes(value.cat_name)}
                                />
                                <span>{value.cat_name}</span>
                              </li>
                            
                          
                        ))}
                    </ul>
              </div>
            </div>
          </div>
    </aside>
    </BrowserView>
    <MobileView></MobileView>
        </>
  );
}
export default Productlistsidebar;